// @flow

import React from 'react'
import { Link } from 'gatsby-plugin-intl'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import baseStyles from './TileNav.scss'

type Props = {
  ...StyleProps,
  title?: string,
  list: Array<Object>
}

const TileNav = ({ styles, title, list }: Props) => (
  <div className={styles.root}>
    {title && (
      <div className={styles.title}>
        {title}
      </div>
    )}
    <div className={styles.tiles}>
      {list.map((item, i) => (
        <div className={styles.tile} key={i}>
          <Link to={item.link} className={styles.tileLink}>
            {item.img}
          </Link>
        </div>
      ))}
    </div>
  </div>
)

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(TileNav)
