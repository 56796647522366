// @flow

import React from 'react'
import Layout from '../../../components/Layout/index'
import Header from '../../../modules/Header'
import Footer from '../../../modules/Footer'
import { useIntl } from 'gatsby-plugin-intl'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import SubpageHeroTeaser from '../../../components/SubpageHeroTeaser'
import IntroTeaser from '../../../components/IntroTeaser'
import Services from '../../../components/Services'
import ServicesNav from '../../../components/ServicePages/ServicesNav'
import TileNav from '../../../components/TileNav'

import messages from '../../../components/ServicePages/ServicePages.lang'
import metaDescription from '../../../messages/pageMetaDescriptions.lang'
import pageTitle from '../../../messages/pageTitles.lang'
import baseStyles from '../../../components/ServicePages/ServicePages.scss'
import SEO from '../../../components/seo'
import { StaticImage } from 'gatsby-plugin-image'

const IntroImage = (
  <StaticImage
    src="../../../images/services/subpages/hcms-integration.jpg"
    objectFit="cover"
    alt=""
    className="image"
  />
)

const HeroTeaserImage = (
  <StaticImage
    src="../../../images/heroteaser/service_heroteaser_bg_desktop.jpg"
    objectFit="cover"
    alt=""
    className="image"
  />
)

import strapiLogoSrc from '../../../images/logo/strapi-business-partner.png'
const strapiLogo = <img src={strapiLogoSrc} className="image" alt="Strapi" />
import contentstackLogoSrc from '../../../images/logo/contentstack.svg'
const contentstackLogo = <img src={contentstackLogoSrc} className="image" alt="Contentstack" />

type Props = {
  ...StyleProps
}

const pageNavigationTiles = [
  {
    link: '/services/headless-cms-integration/strapi/',
    img: strapiLogo
  },
  {
    link: '/services/headless-cms-integration/contentstack/',
    img: contentstackLogo
  }
]

const ServicePage = ({ styles }: Props) => {
  const { formatMessage, locale } = useIntl()

  return (
    <Layout footer={<Footer />} header={<Header withTeaser />}>
      <SEO
        title={formatMessage(pageTitle.TitleHcmsIntegration)}
        description={formatMessage(metaDescription.hcmsIntegrationDescription)}
        lang={locale}
      />
      <ServicesNav />
      <div className={styles.root}>
        <SubpageHeroTeaser title={formatMessage(messages.hcmsIntegrationTitle)} image={HeroTeaserImage} />
        <IntroTeaser
          title={formatMessage(messages.hcmsIntegrationIntroTitle)}
          copy={
            <>
              <div
                className={styles.text}
                dangerouslySetInnerHTML={{
                  __html: formatMessage(messages.hcmsIntegrationIntroBody).replaceAll("'", '')
                }}
              />
              <TileNav list={pageNavigationTiles} title={formatMessage(messages.hcmsIntegrationTileNavTitle)} />
            </>
          }
          image={IntroImage}
          fullWidth
        />
      </div>
      <Services
        services={[
          'consulting',
          'conceptStrategy',
          'design',
          'development',
          'systemIntegration',
          'systemEngineering',
          'appDevelopment',
          'hcmsIntegration'
        ]}
      />
    </Layout>
  )
}
export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(ServicePage)
